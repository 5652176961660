import React from "react"
import Bilder from "../components/common/Bilder"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function BilderPage() {
  return (
    <Layout>
      <SEO title="Bilder" />
      <div className="">
        <Bilder />
      </div>
    </Layout>
  )
}
